import React from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';

function ARProductPreview(props) {
  const {size, product} = props;
  const [extScriptLoaded, setExtScriptLoaded] = React.useState(false);
  const [modelLoaded, setModelLoaded] = React.useState(false);

  React.useState(() => {
    setTimeout(() => {
      setExtScriptLoaded(true);
    }, 1000);
  }, []);

  const extScriptElem = (
    <Helmet>
      <script
        type="module"
        src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
    </Helmet>
  );

  if (!extScriptLoaded) {
    return (
      <Wrapper size={size} modelLoaded={false}>
        <div className="model-loading">
          <h2>Loading...</h2>
        </div>
      </Wrapper>
    );
  }

  function onModelViewerLoaded(elem) {
    console.log({onModelViewerLoaded: elem});

    if (elem) {
      setTimeout(() => {
        if (elem && elem.shadowRoot) {
          const userInputElem = elem.shadowRoot.querySelector('.userInput');
          userInputElem.style.marginTop = '-120px';
          setTimeout(() => {
            setModelLoaded(true);
          }, 500);
        }
      }, 3500);
    }
  }

  return (
    <>
      {extScriptElem}

      <Wrapper size={size} modelLoaded={modelLoaded}>
        <div className="model-loading" style={{opacity: modelLoaded ? 0 : 1}}>
          <h2>Loading...</h2>
        </div>

        <model-viewer
          ref={onModelViewerLoaded}
          src={product.model_url}
          ar
          ar-modes="webxr scene-viewer quick-look"
          camera-controls
          poster="poster.webp"
          shadow-intensity="1"
        />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: ${(props) => props.size}px;
  height: 800px;
  position: relative;

  & model-viewer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    user-select: none;
    display: flex;
  }

  & .model-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    background-color: black;
    opacity: 1;
    transition: all 600ms;
    pointer-events: none;
    z-index: 10;
  }
`;

export default ARProductPreview;
