import React from 'react';
import styled from 'styled-components';
import VRProduct from '../../Utils/VRProduct';

export default function VRProductPreview(props) {
  const {product, size = 300} = props;
  const vrProductRef = React.useRef();

  const productId = product?.id;

  React.useEffect(() => {
    function init() {
      if (!vrProductRef.current) {
        vrProductRef.current = new VRProduct({
          rootId: 'rev-vr-product',
          objUrl: 'https://threejs.org/examples/models/obj/male02/male02.obj',
          mtlUrl: 'https://threejs.org/examples/models/obj/male02/male02.mtl',
          size,
        });
      } else {
        vrProductRef.current.update({size});
      }
    }

    setTimeout(init, size);
  }, [productId, size]);

  return (
    <Wrapper size={size}>
      <div id="rev-vr-product" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;

  & #rev-vr-product {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
`;
