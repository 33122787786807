import styled from 'styled-components';
import React from 'react';

const CircleButton = (props) => {
  const {icon, onClick, size = 40, ...rest} = props;
  return (
    <StyledCircleButton size={size} onClick={onClick} {...rest}>
      {icon}
    </StyledCircleButton>
  );
};

const StyledCircleButton = styled.button`
  width: ${({size}) => `${size}px`};
  height: ${({size}) => `${size}px`};
  padding: 10px;
  display: grid;
  place-items: center;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: var(--snow-owl-theme-color);
  transition: all 300ms ease;
  cursor: pointer;
  &:active {
    background-color: var(--snow-owl-theme-button-hover-color););
  }
  
`;

export default CircleButton;
