import React from 'react';
import styled from 'styled-components';
import {message, Tabs, Tooltip} from 'antd';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import qs from 'query-string';
import BreadcrumbBar from 'rev.sdk.js/Templates/ProductList/BreadcrumbBar';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {Share as LinkIcon} from '@styled-icons/bootstrap/Share';
import * as AppActions from '../../AppActions';
import Carousel from '../../Components/Carousel';
import FixedRatioImage from '../../Components/FixedRatioImage';
import {THEME_COLOR} from '../../constants';
import {gtag} from 'rev.sdk.js/Utils/Tracker';
import CircleButton from '../../Components/SnowOwl/CircleButton';
import RectButton from '../../Components/SnowOwl/RectButton';
import VRProductPreview from './VRProductPreview';
import ARProductPreview from './ARProductPreview';

function ProductDetail(props) {
  const {
    pageContext: {
      collection = 'product',
      prefixPath = '/product',
      listViewPath = '/products',
      outlets = {categoryDisplayMap: 'categoryDisplayMap'},
    },
    renderCustomSection = (sectionId, product) => null,
  } = props;
  const [activeSummaryTab, setActiveSummaryTab] = React.useState('description');
  const [product, setProduct] = React.useState(null);
  const [article, setArticle] = React.useState(null);
  const [imgIdx, setImgIdx] = React.useState(0);
  const [user] = useOutlet('user');
  const [landing] = useOutlet('landing');
  const showLoginModal = useOutletSetter('login-modal');
  const [dimension] = useOutlet('dimension');
  const params = qs.parse(props.location.search);
  const showPrice = !!user || !landing?.hide_price_for_user_not_login;
  const {id} = params;

  React.useEffect(() => {
    async function fetchData() {
      try {
        AppActions.setLoading(true);
        const resp = await JStorage.fetchOneDocument(collection, {id});
        setProduct(resp);
        setImgIdx(0);
        gtag('event', 'view_item', {
          currency: 'TWD',
          value: resp.price,
          items: [resp].map((p) => ({
            item_id: p.id,
            item_name: p.name,
          })),
        });
        AppActions.setLoading(false);

        if (resp.description_article) {
          setArticle(
            await JStorage.fetchOneDocument('Article_Default', {
              id: resp.description_article,
            }),
          );
        }
      } catch (ex) {
        console.warn(ex);
      } finally {
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, [id, collection]);

  async function copyProductShareUrl() {
    let _url = AppActions.getReurl({
      title: encodeURIComponent(product.name),
      image: product.og_image || '',
      redirectUrl: `${window.location.origin}/product?id=${product.id}`,
    });
    try {
      await navigator.clipboard.writeText(_url);
      gtag('event', 'share', {
        method: 'url',
        content_type: 'product',
        item_id: product.id,
      });
      message.success(`已複製分享連結`);
    } catch (err) {
      console.log(err);
      message.warn(`無法複製連結`);
    }
  }

  async function addToCart() {
    if (!user) {
      showLoginModal(true);
      return;
    }

    try {
      AppActions.setLoading(true);
      await Cart.addToCart(product.id, {
        qty: 1,
      });
      gtag('event', 'add_to_cart', {
        currency: 'TWD',
        value: product.price * 1,
        items: [product].map((p) => ({
          item_id: p.id,
          item_name: p.name,
        })),
      });
      message.success('成功');
    } catch (ex) {
      console.warn(ex);
      if (ex.response.error === 'item_length_limit') {
        message.error('超過購物車購買上限，請分批購買');
      } else {
        message.error('發生錯誤, 請稍後再重新嘗試');
      }
    } finally {
      AppActions.setLoading(false);
    }
  }

  if (!product) {
    return (
      <Wrapper>
        <div className="content" />
      </Wrapper>
    );
  }

  let gallerySize = 450;
  if (dimension.innerWidth) {
    if (dimension.innerWidth < 450 + 20 * 2) {
      gallerySize = dimension.innerWidth - 20 * 2;
    }
  }

  return (
    <Wrapper>
      <div className="content">
        <TopSection>
          <Gallery dimension={dimension} size={gallerySize}>
            {product.images && product.images[imgIdx] && (
              <Carousel
                currIdxFromParent={imgIdx}
                width={gallerySize}
                height={gallerySize}
                data={product.images.map((i) => i.expected_url)}
                renderPrev={null}
                renderNext={null}
                renderDots={null}
                renderItem={({item}) => {
                  return (
                    <FixedRatioImage
                      extraCss={{borderRadius: 15}}
                      image={item}
                      width="100%"
                      ratio={1}
                      mode="cover"
                      alt="product"
                    />
                  );
                }}
                disableSwipe
              />
            )}

            {product.images && (
              <MiniImageList>
                {product.images.map((image, idx) => (
                  <MiniImageItem
                    src={image.expected_url}
                    alt="mini"
                    key={idx}
                    selected={idx === imgIdx}
                    onClick={() => setImgIdx(idx)}
                  />
                ))}
              </MiniImageList>
            )}
          </Gallery>

          {product.model_url && (
            <ARProductPreview product={product} size={gallerySize} />
          )}

          <div style={{flexBasis: 20}} />

          <Summary>
            {product.labels && product.labels[0] && (
              <div style={{marginTop: 15}}>
                <BreadcrumbBar
                  cat={product.labels[0]}
                  updateCat={async (nextCat) => {
                    await AppActions.navigate(
                      `${listViewPath}?cat=${nextCat}&q=${product.artist_id}`,
                    );
                  }}
                  categoryDisplayMap={outlets.categoryDisplayMap}
                />
              </div>
            )}

            <div className="header">
              <h2>{product.name}</h2>
              <Tooltip title="複製分享連結">
                <CircleButton
                  icon={<LinkIcon color={THEME_COLOR} />}
                  onClick={copyProductShareUrl}
                />
              </Tooltip>
            </div>

            <LineSeparator />

            <InputField style={{justifyContent: 'flex-start'}}>
              <label>Sale Amount</label>
              <span>{product.stock}</span>
            </InputField>

            <InputField style={{justifyContent: 'flex-start'}}>
              <label>Token Standard</label>
              <span>ERC-721</span>
            </InputField>

            <InputField style={{justifyContent: 'flex-start'}}>
              <label>Blockchain</label>
              <span>Ethereum</span>
            </InputField>

            <LineSeparator />

            <InputField style={{justifyContent: 'flex-start'}}>
              <div>
                <small>Current Price</small>
                {showPrice && <h2>NTD ${product.price}</h2>}
                {!showPrice && <h2>NTD 登入即見</h2>}
              </div>
            </InputField>

            <LineSeparator />

            <InputField style={{justifyContent: 'flex-start'}}>
              <RectButton outline={false} onClick={addToCart}>
                加入購物車
              </RectButton>
            </InputField>
          </Summary>
        </TopSection>

        {renderCustomSection('E', {product})}

        <Tabs activeKey={activeSummaryTab} onChange={setActiveSummaryTab}>
          <Tabs.TabPane tab="Description" key="description">
            {article && (
              <ArticlePreview
                dangerouslySetInnerHTML={{
                  __html: article.html,
                }}
              />
            )}
          </Tabs.TabPane>
        </Tabs>

        {renderCustomSection('F', {product})}
      </div>

      {renderCustomSection('G', {product})}
    </Wrapper>
  );
}

const ArticlePreview = styled.div`
  & img {
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  background-color: var(--snow-owl-theme);

  & * {
    color: var(--snow-owl-theme-font-color);
  }
  & > .content {
    min-height: calc(100vh - var(--bottomSiteFooterHeight));
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: var(--basePadding);
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;
`;

const Gallery = styled.div`
  width: ${(props) => props.size}px;
  margin-bottom: 20px;

  & > img:first-child {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    object-fit: contain;
  }
`;

const Summary = styled.div`
  & > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & h2 {
    font-size: 32px;
    line-height: 1.57;
  }

  & h3 {
    font-size: 21px;
  }

  & > p {
    margin: 20px 0;
    font-size: 17px;
  }

  flex: 1;
  flex-basis: 450px;
  margin: 0 10px;
`;

const LineSeparator = styled.section`
  border-top: 1px solid #7d838bcc;
  margin: 20px 0;
`;

const InputField = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  & > label {
    min-width: 150px;
    font-size: 14px;
    color: var(--snow-owl-theme-font-color);
    letter-spacing: 0.5px;
    line-height: 20px;
    font-weight: 500;
  }
  & > span {
    font-size: 14px;
    color: var(--snow-owl-theme-font-color);
    letter-spacing: 0.5px;
    line-height: 20px;
    font-weight: 200;
    opacity: 0.7;
  }
`;

const ProductArticle = styled.div`
  min-height: 700px;
`;

const MiniImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MiniImageItem = styled.img`
  width: 64px;
  height: 64px;
  margin: 10px;
  border: 2px solid ${(props) => (props.selected ? '#ccc' : 'transparent')};
  border-radius: 4px;
  object-fit: contain;
  cursor: pointer;

  :first-child {
    margin-left: 0px;
  }
`;

export default ProductDetail;
